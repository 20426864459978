import React, {Component} from "react";

class Design4 extends Component{

    render(){
        return(
          <section className="pt-5" id="marketer">

          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h5 className="text-secondary">Optimiser</h5>
                <p className="mb-2 fs-8 fw-bold">Règler le compas</p>
                <p className="mb-4 fw-medium text-secondary">Centraliser, acceder et traiter facilement les informations de l'enseignant que de l'élève <br />voir les synthèses des activités quotidiennes <br /> suivre l'enseignant et l'élève ainsi que autre intervenant dans le processus.</p>
                <h4 className="fw-bold fs-1">Faciliter</h4>
                <p className="mb-4 fw-medium text-secondary">Le traitement et l'accès à toutes données disponibles dans le système,<br />informations de l'enseignant et élève</p>
                <h4 className="fw-bold fs-1">Conserver</h4>
                <p className="mb-4 fw-medium text-secondary">Le parcours de l'élève et activités de l'enseignant<br />archiver les données,</p>
                <h4 className="fw-bold fs-1">Organiser</h4>
                <p className="mb-4 fw-medium text-secondary">Laisez orignaser le processus scolaire<br />gain et gestion du temps,</p>
              </div>
              <div className="col-lg-6"><img className="img-fluid" src="assets/img/marketer/marketer.png" alt="" /></div>
            </div>
          </div>
  
        </section>
        )
    }
}
export default Design4;