import axios from "axios";
import React, {Component} from "react";

class Reportcard extends Component{

    state = {
        student : {
            user : {
                user : {}
            },
            establishment : {

            }
        },
        discipline : true,
        reportcard : true,
        schoolfee : true,
        studentschoolfees : null,
        labels : [],
        months : [],
        disciplines : [],
    }
    
    componentDidMount(){
        this.getStudent()
    }
 
    handleInput = (e) => {
        var x = e.target.value;
        if(this.state.schoolfee.filter(d => d ==e).length == 0)
            this.state.schoolfee[e] = true
        else{
            this.state.schoolfee[e] = false
        }
    }

    handleInputD = (e) => {
        this.setState({
            discipline: !this.state.discipline
        }); 
    }

    handleInputR = (e) => {
        this.setState({
            reportcard: !this.state.reportcard
        }); 
    }

    async getStudent(){
        var student = await axios.get("userapi/" + this.props.userId);
        
        if(student.status === 200)
        {
            this.setState({
                student : student.data
            })
            
            this.getReportcards(student.data.id);
            this.getStudentschoolfees(student.data.id);
            this.getDisciplines(student.data.id);
            this.getMonth();
        }
    }

    async getMonth(){
        var months = await axios.get("months");
        
        if(months.status === 200)
        {
            this.setState({
                months : months.data
            })
        }
    }

    async getDisciplines(student){
        var disciplines = await axios.get("disciplines/" + student);
        
        if(disciplines.status === 200)
        {
            this.setState({
                disciplines : disciplines.data
            })
        }
    }

    async getReportcards(studentId){
        var evaluations = await axios.get('/reportcards/' + studentId);
        
        if(evaluations.status === 200)
        {
            var data = [], max = 1, rows = [] , labels = [], points =[];
            if(evaluations.data.reportcards.length > 0)
            {
                evaluations.data.reportcards.map((evaluation) => {
                // evaluations.data.reportcards.filter(stat => stat.status == 1).map((evaluation) => {
                    var noteD = 0, max = 0;
                    
                    evaluation.notes.map(note => {
                         
                        noteD += note.note > 0 ? note.note : noteD;
                        max += note.evaluation.max > 0 ? note.evaluation.max : max;
                            rows.push(
                                {
                                    Periode : evaluation.periode.id + "P",
                                    lesson : evaluation.sectionclasselesson.lesson && ( evaluation.sectionclasselesson.lesson.short_entitled_lesson),
                                    note : note.note +" / "+ max,
                                    staff : evaluation.sectionclasselesson && (evaluation.sectionclasselesson.staff && (evaluation.sectionclasselesson.staff.user.first_name + " " +evaluation.sectionclasselesson.staff.user.last_name)),
                                    created_at : <small>{note.createdAt.slice(0, 10)}</small>
                                }
                            )
                    })
                    if(max > 0 && evaluation.sectionclasselesson.lesson && evaluation.sectionclasselesson.lesson.short_entitled_lesson)
                    {
                        if(labels.filter(data => data.id == evaluation.sectionclasselesson.lesson.id).length == 0)
                        {
                            labels.push({
                                note :  noteD,
                                max : max,
                                lesson : evaluation.sectionclasselesson.lesson && ( evaluation.sectionclasselesson.lesson.entitled_lesson),
                                staff : evaluation.sectionclasselesson.staff && ( evaluation.sectionclasselesson.staff.user.last_name + " " + evaluation.sectionclasselesson.staff.user.first_name),
                                id : evaluation.sectionclasselesson.lesson && ( evaluation.sectionclasselesson.lesson.id),
                            });
                            noteD = (noteD * 100) /max;
                        }
                        else{
                            labels.map((val, index) => {
                                if( val.id == evaluation.sectionclasselesson.lesson.id)
                                {
                                    labels[index].note =  labels[index].note ? labels[index].note > 0 ? labels[index].note + noteD : noteD : noteD
                                    labels[index].max =  labels[index].max ? labels[index].max > 0 ? labels[index].max + max : max : max
                                    labels[index].lesson = evaluation.sectionclasselesson.lesson.entitled_lesson;
                                    labels[index].staff = evaluation.sectionclasselesson.staff && ( evaluation.sectionclasselesson.staff.user.last_name + " " + evaluation.sectionclasselesson.staff.user.first_name );
                                    labels[index].id = evaluation.sectionclasselesson.lesson.id
                                }
                            })
                        } 

                    }
                    
                    
                })

            }
            
            this.state.student && (
                this.state.student.reportcards.filter(data => data.periode.id == 1).map((val) =>{
                    if(labels.filter(lab => lab.id == val.sectionclasselesson.lesson.id).length == 0)
                    {
                        labels.push({
                            id : val.sectionclasselesson.lesson.id,
                            lesson : val.sectionclasselesson.lesson.entitled_lesson,
                            staff : val.sectionclasselesson && (val.sectionclasselesson.staff && (val.sectionclasselesson.staff.user.first_name + " " +val.sectionclasselesson.staff.user.last_name)),
                            note : 0,
                            max :  0,
                            pourcent : 0,
                        })
                    }
                })
            )
            this.setState({
                labels : labels,
            }) 
        }
    }

    async getStudentschoolfees(studentId){
        var studentschoolfees = await axios.get('/schoolfees/'+ studentId)
        if(studentschoolfees.status === 200)
        {
            this.setState({
                studentschoolfees : studentschoolfees.data
            })
        }
    }


    render(){
        var profile = JSON.parse(localStorage.getItem('profile'))
        var matricule =this.state.student.matricule; 
        var last_name = this.state.student.user ? this.state.student.user.last_name : "";
        var first_name = this.state.student.user ? this.state.student.user.first_name : "";
        var family_name = this.state.student.user ? this.state.student.user.family_name : "";
        var classe= this.state.student && (this.state.student.sectionclass && (this.state.student.sectionclass.class.short_entitled_class + " " + this.state.student.sectionclass.title )); 
        var section= this.state.student && (this.state.student.sectionclass && ( this.state.student.sectionclass.section.entitled_section )); 
        return(
            
        <div className="container mt-4">
            <div className="row">
                   
                <div className="col-md-12">
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body  row">
                            <div className="col-md-6">
                                <div className="bg-primary text-center text-white font-monospace fw-bolder my-2">Identité de l'élève</div>
                                <div className="d-flex">
                                    <div className="image mr-3">
                                        <img src={this.state.student && (this.state.student.user.avatar)} className="img-fluid" width={this.state.student && (this.state.student.user.avatar && ("70"))} /> 
                                    </div>
                                    <div className="mt-0 ms-2">
                                        <div  className="">
                                            <div><span className="font-cursive">Nom : </span> {last_name }</div>
                                            <div><span className="font-cursive">Prén : </span> { first_name }</div>
                                            <div><span className="font-cursive">Post : </span> { family_name}</div>
                                            <div><span className="font-cursive">Matr : </span> {this.state.student && (this.state.student.matricule)}</div>
                                            <div><span className="font-cursive">Ident : </span> {this.state.student && (this.state.student.user.identity_number)}</div>
                                            <div><span className="font-cursive">Cont : </span> {this.state.student && (this.state.student.user.tel)},  {this.state.student && (this.state.student.user.email)}</div>
                                            <div><span className="font-cursive">adre: </span> {this.state.student && (this.state.student.user.adress)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="bg-primary text-center text-white font-monospace fw-bolder my-2">Identité du parent</div>
                                <div className="d-flex">
                                    <div className="image mr-3">
                                        <img src={this.state.student && (this.state.student.user.user.avatar)} className="img-fluid d-none" width={this.state.student && (this.state.student.user.user.avatar && ("70"))} /> 
                                    </div>
                                    <div className="mt-0 ms-2">
                                        <div  className="">
                                            <div><span className="font-cursive">Nom : </span> {this.state.student && ( this.state.student.user.user.last_name)} </div>
                                            <div><span className="font-cursive">Prén : </span> {this.state.student && ( this.state.student.user.user.first_name)} </div>
                                            <div><span className="font-cursive">Ident : </span> {this.state.student && (this.state.student.user.user.identity_number)}</div>
                                            <div><span className="font-cursive">Cont : </span> {this.state.student && (this.state.student.user.user.tel)},  {this.state.student && (this.state.student.user.user.email)}</div>
                                            <div><span className="font-cursive">adre : </span> {this.state.student && (this.state.student.user.user.adress)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 row">
                                <div className="bg-primary text-center text-white font-monospace fw-bolder my-2">Etablissement</div>
                                <div className="col-md-6">
                                    <div className="d-flex">
                                        <div className="mt-0 ms-2">
                                            <div  className="">
                                                <div><span className="font-cursive">Clas : </span> { classe} </div>
                                                <div><span className="font-cursive">Sect : </span> { section } </div>
                                                <div><span className="font-cursive">Ann : </span> {this.state.student && (this.state.student.year ? this.state.student.year.year : "")} </div>
                                            </div>
                                        </div>
                                    </div><br />
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex">
                                        <div className="image mr-3">
                                            <img src={this.state.student && ( this.state.student.establishment.logo )} className="img-fluid d-none" width={this.state.student && (this.state.student.establishment.logo && ("70"))} /> 
                                        </div>
                                        <div className="mt-0 ms-2">
                                            <div  className="">
                                                <div><span className="font-cursive">Etab : </span> { this.state.student && ( this.state.student.establishment.name )} </div>
                                                <div><span className="font-cursive">Type : </span> { this.state.student && ( this.state.student.establishment.type )}</div>
                                                <div><span className="font-cursive">Cont : </span> { this.state.student && ( this.state.student.establishment.email)} , { this.state.student && ( this.state.student.establishment.tel)}</div>
                                                <div><span className="font-cursive">adre : </span> { this.state.student && ( this.state.student.establishment.region)}, { this.state.student && ( this.state.student.establishment.municipality)} { this.state.student && ( this.state.student.establishment.city)}, { this.state.student && ( this.state.student.establishment.other_adress)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 font-monospace mt-4">
                                <div className="text-center text-primary d-none table_action">
                                    <input type={"checkbox"} className="rounded rounded-3" name="reportcard" value={this.state.reportcard} id="reportcard" onChange={ this.handleInputR } /> <label className="" htmlFor="reportcard">Afficher les parcours scolaire</label>
                                 </div>
                                <div className={this.state.reportcard ? "bg-primary text-center text-white font-monospace fw-bolder my-2" : "bg-primary text-center text-white font-monospace fw-bolder my-2 d-none"}>Parcours scolaire</div>
                                <div className={this.state.reportcard ? "row" : "row d-none" }>
                                    {
                                        this.state.labels.sort((a, b) => (a.max < b.max ? 1 : -1)).map((label, index) =>{
                                            return(
                                                <div className="col-md-4">
                                                    <div className="card shadow mb-2 border rounded rounded-1">
                                                        <div className="card-body my-2 text-center">
                                                            <h6 className="h6 text-primary font-cursive">{ label.lesson }</h6>
                                                            <div className={ label.note < (label.max /2) ? "text-red" : ""}>{ label.note > 0 ? label.note : "" }/{ label.max > 0 ? label.max : "" }</div>
                                                            <div className={ label.note < (label.max /2) ? "text-red" : ""}>{ label.note ? (label.note > 0 && label.note > 0) ? ((label.note * 100) / label.max).toFixed(1) + "%" : "" : ""  }</div>
                                                            {/* <div className={ label.note < (label.max /2) ? "text-red" : ""}>{ label.note ? (label.note > 0 && label.note > 0) ? ((label.note * 100) / label.max).toFixed(1) + "%" : "" : ""  }</div> */}
                                                            <div className="font-cursive">{ label.staff }</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="col-md-12 font-monospace mt-4">
                                 <div className="text-center text-primary d-none table_action">
                                    <input type={"checkbox"} className="rounded rounded-3" name={"schoolfee["+  this.props.userId +"]"} value={this.state.schoolfee[ this.props.userId]} id="schoolfee" onChange={ this.handleInput } /> <label className="" htmlFor="schoolfee">Afficher les paiements scolaire</label>
                                 </div>
                                <div className={this.state.schoolfee ? "bg-primary text-center text-white font-monospace fw-bolder my-2" : "bg-primary text-center text-white font-monospace fw-bolder my-2 d-none"}>Paiements scolaire</div>
                                <div className={this.state.schoolfee ? "row" : "row d-none" }>
                                    {
                                        this.state.studentschoolfees != null &&(
                                            this.state.studentschoolfees.schoolfees.filter(sh => this.state.studentschoolfees.status == 1).map((schoolfee, index) =>{
                                                return(
                                                    <div className="col-md-4">
                                                        <div className="card shadow mb-2 border rounded rounded-1">
                                                            <div className="card-body my-2 text-center">
                                                                <h6 className="h6 font-cursive">{ schoolfee.month.month }</h6>
                                                                <div className="font-monospace">{ schoolfee.invoice }. { schoolfee.sectionschoolfeecategory.schoolfeecategory.category }</div>
                                                                <div className="text-center m-2"><span className="border rounded rounded-2 text-primary p-2">{ schoolfee.pay_mount + ""+ schoolfee.sectionschoolfeecategory.currency }</span></div>
                                                                <div className="font-cursive small">{ schoolfee.user.last_name +  " " + schoolfee.user.first_name }, <small>{ schoolfee.createdAt.slice(0, 10) }</small></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                    }
                                </div>
                                
                            </div>

                            <div className="col-md-12 font-monospace mt-4">
                                 <div className="text-center text-primary d-none table_action">
                                    <input type={"checkbox"} className="rounded rounded-3" name="discipline" value={this.state.discipline} id="discipline" onChange={ this.handleInputD } /> <label className="" htmlFor="discipline">Afficher discipline</label>
                                 </div>
                                <div className={this.state.discipline ? "bg-primary text-center text-white font-monospace fw-bolder my-2" : "bg-primary text-center text-white font-monospace fw-bolder my-2 d-none"}>Discipline</div>
                                <div className={this.state.discipline ? "row" : "row d-none" }>
                                    {
                                        this.state.disciplines.map((discipline, index) =>{
                                            return(
                                                <div className="col-md-4">
                                                    <div className="card shadow mb-2 border rounded rounded-1">
                                                        <div className="card-body mb-0 text-center">
                                                            <h6 className="h6 font-cursive">{ discipline.motif }</h6>
                                                            <div className="font-monospace small">{ discipline.description }.</div>
                                                        </div>
                                                            <div className="text-end m-2 mt-0 small"><small>{ discipline.createdAt.slice(0, 10) }</small></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                
                            </div>
                             
                        </div>
                    </div>
 
                    
                </div>
                
            </div>
            <hr />
        </div>
        )
    }
}
export default  Reportcard