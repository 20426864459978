import React, {Component} from "react";
import Design from "../components/Design";
import Support from "../components/Support";
import Intervenant from "../components/Intervenant";
import Design3 from "../components/Design3";
import Design4 from "../components/Design4";
// import Expert from "../components/Expert";
// import Avantage from "../components/Avantage";
// import Design7 from "../components/Design7";
// import Design8 from "../components/Design8";
import Profile from "./Profile";
import axios from "axios";
import Navbar from "../partials/Navbar";
import Design7 from "../components/Design7";
import Design8 from "../components/Design8";
import Login from "../components/Login";

class Dashboard extends Component{

    state = {

    }

    componentDidMount(){
        this.refreshToken()
    }

    async refreshToken () {
        try{
          var token = await axios.get('token');
          if(token.status === 200)
          {
              var user = (token.data.data);
              localStorage.setItem("user", JSON.stringify(user));
          }
          else if(token.status === 401)
          {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
          }
        }catch(error) {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
        }
    }

    render(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : false;
        return(
            <main className="main" id="top">
                <Navbar />
                <div>
                        <Design />
                        <Support />
                        <Intervenant />
                        <Design3 />
                        <Design4 />
                        { 
                            user != false && (
                                <Profile />
                            )
                        }
                    </div>
                <Design7 />
                <Design8 />
                <Login />
            </main>
            
        )
    }
}
export default Dashboard;