import React, {Component} from "react";

class Support extends Component{

    render(){
        return(
          <section className="pt-5 pt-md-9 mb-6" id="support">

          <div className="bg-holder z-index--1 bottom-0 d-none d-lg-block" style={{backgroundImage:"url(assets/img/category/shape.png)", opacity:".5"}}>
          </div>
  
          <div className="container">
            <h1 className="fs-6  fw-bold mb-4 text-center"> Réponse des opérations <br className="d-none d-xl-block" /> quotidiennes </h1>
            <div className="row">
              <div className="col-lg-3 col-sm-6 mb-2  text-center"> <img className="mb-3 ms-n3" src="assets/img/category/icon1.png" width="75" alt="Feature" />
                <h4 className="mb-3">prêt à votre attente</h4>
                <p className="mb-0 fw-medium text-secondary">Suivi du parcours de l'élève,</p>
              </div>
              <div className="col-lg-3 col-sm-6 mb-2  text-center"> <img className="mb-3 ms-n3" src="assets/img/category/icon2.png" width="75" alt="Feature" />
                <h4 className="mb-3">Globalité</h4>
                <p className="mb-0 fw-medium text-secondary">Collaboration interne et externe entre le responsable de l'école et les parents</p>
              </div>
              <div className="col-lg-3 col-sm-6 mb-2  text-center"> <img className="mb-3 ms-n3" src="assets/img/category/icon3.png" width="75" alt="Feature" />
                <h4 className="mb-3">Votre choix</h4>
                <p className="mb-0 fw-medium text-secondary">Adaptable selon votre mode et secteur.</p>
              </div>
              <div className="col-lg-3 col-sm-6 mb-2  text-center"> <img className="mb-3 ms-n3" src="assets/img/category/icon4.png" width="75" alt="Feature" />
                <h4 className="mb-3">Restez informer</h4>
                <p className="mb-0 fw-medium text-secondary">Recevoir un sms en cas de paiement de l'élève ou de son programme d'evaluation.</p>
              </div>
            </div>
            {/* <div className="text-center"><button className="btn btn-primary btn-sm  me-3 btn-lg" type="button"  data-bs-toggle="modal" data-bs-target="#popupLogin" >Commencer maintenant</button></div> */}
          </div>
  
        </section>
        )
    }
}
export default Support;