import React, {Component} from "react";

class Design3 extends Component{

    render(){
        return(
          <section className="pt-5" id="manager">

            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left">
                  <h5 className="text-secondary">Prise de la decision</h5>
                    <p className="fs-7 fw-bold mb-2">Responsable</p>
                </div>
                <div className="col-lg-6"><img className="img-fluid" src="assets/img/manager/manager.png" alt="" /></div>
                <div className="col-lg-6">
                  <p className="mb-4 fw-medium text-secondary">
                    Suivre le processus des activités de l'enseignant et fournir tout le parcours de l'élève <br />prise en charge de l'enseignant et de l'élève.
                  </p>
                  <div className="d-flex align-items-center mb-3"> <img className="me-sm-4 me-2" src="assets/img/manager/tick.png" width="35" alt="tick" />
                    <p className="fw-medium mb-0 text-secondary">Gestion scolaire des resources scolaire <br />Tout intervenant dans le processus.</p>
                  </div>
                  <div className="d-flex align-items-center mb-3"> <img className="me-sm-4 me-2" src="assets/img/manager/tick.png" width="35" alt="tick" />
                    <p className="fw-medium mb-0 text-secondary">Suivi des paiements scolaire<br /> de l'élève</p>
                  </div>
                  <div className="d-flex align-items-center mb-3"><img className="me-sm-4 me-2" src="assets/img/manager/tick.png" width="35" alt="tick" />
                    <p className="fw-medium mb-0 text-secondary"> Statistique des parcours des élèves ainsi que les activités<br /> effectuées.</p>
                  </div>
                </div>
              </div>
            </div>
    
          </section>
        )
    }
}
export default Design3;