import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';

class Navbar extends Component{

  state = {
    count : "3",
    chats : [],
  }

  componentDidMount(){
    this.getNotifications()
  }

  async getNotifications()
  {
    var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : false;

      var chats = await axios.get('notifications');
      if(chats.status == 200)
      {
          this.setState({
              chats : chats.data,
              loading : true
          })
      }
  } 

 
  logout = (e) => {
    axios.post('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
    window.localStorage.clear();
    window.location.replace(process.env.REACT_APP_EVIVESOMA)
}

    render(){
      var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : false;
      
      var exist = [], count = 0;
        this.state.chats.map((box) => {
            if(exist.filter(dat => dat == box.id).length == 0)
            {
              exist.push(box.id)
                if(box.userchats.readAt){
                    count = count;
                }
                else{
                    count = count + 1;
                }
            }
        })

      
      return(
        <nav className="navbar opacity-100 navbar-expand-lg navbar-light sticky-top  py-0" data-navbar-on-scroll="data-navbar-on-scroll" style={{ backgroundColor : "#045fc0 !important"}}>
            <div className="container  py-0 mt-0 "><Link className="navbar-brand btn" to="/">
                <span className="fs-4 d-sm-inline  p-0 m-0"><img src="/logo192.png" alt="logo evive" className="" height={39+"px"}  /></span> EviveSoma </Link>
              {/* <button className="navbar-toggler btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"> </span></button> */}
              <div className="border-bottom border-lg-0 mt-lg-0 flex-row-reverse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                  
                  <li className="nav-item ms-1 border border-0"><a className="nav-link btn px-1" aria-current="page" href={ process.env.REACT_APP_EVIVESOMA + "/#intervenant" }><FeatherIcon icon={"home"} size={14}  className="mb-1 fw-bolder"></FeatherIcon>Acceuil</a></li>
                  {/* <li className="nav-item ms-1 border border-0"><a className="nav-link btn px-1" aria-current="page" href="#expert">Expert</a></li>
                  <li className="nav-item ms-1 border border-0"><a className="nav-link btn px-1" aria-current="page" href="#resultat">Resultat</a></li> */}
                  { 
                    user != false && (<>
                      <li className="nav-item ms-1 border border-0"><a className="nav-link btn px-1" aria-current="page" href={ process.env.REACT_APP_EVIVESOMA + "/#profile" }><FeatherIcon icon={"dollar-sign"} size={14}  className="mb-1 fw-bolder"></FeatherIcon>Suivi</a></li>
                      {/* <li className="nav-item ms-1 border border-0"><a className="nav-link btn px-1" aria-current="page" href="http://localhost:3000/rappels">
                      <FeatherIcon icon={"mail"} size={14}  className="mb-1 fw-bolder"></FeatherIcon>
                        Chat
                        <span className=" ms-2 translate-middle badge rounded-pill bg-primary">
                          {this.state.count > 9 ? "+9" : this.state.count > 0 ? this.state.count : "" }
                        </span>
                      </a></li> */}
                      <li className="nav-item ms-1 border border-0"><a className="nav-link btn px-1" aria-current="page" href={ process.env.REACT_APP_EVIVESOMA + "/notifications" }>
                      <FeatherIcon icon={"bell"} size={14}  className="mb-1 fw-bolder text-red"></FeatherIcon>
                        Notification
                        <span className=" ms-2 translate-middle badge rounded-pill" style={{  backgroundColor : "red" }}>
                          { count > 9 ? "+9" :  count > 0 ?  count : "" }
                        </span>
                      </a></li>
                    </>)
                  }
                  </ul>
              </div>
            </div>
          </nav>
        )
    }
}
export default Navbar;