import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import Loading from "../components/Loading";
import Reportcard from "./Reportcard";

class Profile extends Component{

    state = {
        loading : false,
        links : [],
        users : [],
    }

    componentDidMount(){
        this.links()
    }

    async links () {
        var requests = await axios.get('/links')
        if(requests.status == 200)
        {
            this.setState({
                links : requests.data,
                loading : true
            })
        }
        this.getUser()
    }

    async getUser () {
        var requests = await axios.get('/user')
        if(requests.status == 200)
        {
            this.setState({
                users : requests.data,
            })
        }
    }

    render(){
        var user = JSON.parse(localStorage.getItem('user'));
        return(
            <section className="pt-7"  id="profile">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4"></div>
                        <div className="col-md-6 mb-4">
                            <h6 className="h6 font-monospace">Mon profil</h6>
                            <div className="card border shadow border-1 shadow  mb-3   shadow-hover shadow rounded rounded-1">
                                <div className="card-body  d-flex">
                                    <div className="image m-3">
                                        <Link to={'#'+user.id}  className="text-decoration-none">
                                            <img src={user && (user.avatar)} className="rounded-circle img-fluid" width={user && (user.avatar && ("100"))} />
                                        </Link>
                                    </div>
                                    <div>
                                        <div>{ user.name }</div>
                                        <div><a href={"#"} rel="noopener noreferrer" className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16"></FeatherIcon> { user.tel}</a> </div>
                                        <div><a href={"#"} rel="noopener noreferrer" className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16"></FeatherIcon> { user.email}</a> </div>
                                        {/* <div className="text-end float-end"><button className="btn btn-primary btn-sm  py-0 me-3 btn-lg" type="button" >Editer</button></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={ this.state.users ? "d-none" : "text-center"}><a href={ process.env.REACT_APP_EVIVESOMA +"/#profile"} onClick={() => this.links() } className="btn btn-primary btn-sm  me-3 btn-lg mb-2" type="button">Actualiser</a></div>
                        <div className="row">
                            {
                                this.state.links.map(link => {
                                    return(<>
                                        <div className="col-md-4">
                                            <div className="card shadow mb-2  shadow border border-1 mb-2">
                                                <Link to={"/#reportcards"} className="text-decoration-none">
                                                    <img class="card-img-top" src="assets/img/marketing/marketing01.png" alt="" />
                                                    <div className="card-body text-center">
                                                        <h5 className="h5 text-primary font-monospace">{ link.title} parcours</h5>
                                                        <p class="text-secondary"><span class="ms-1 small">Rechercher le cursus scolaire</span></p> 
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="card shadow mb-2  shadow border border-1 mb-2">
                                                <Link to={"/#schoolfees"} className="text-decoration-none">
                                                    <img class="card-img-top" src="assets/img/marketing/marketing02.png" alt="" />
                                                    <div className="card-body text-center">
                                                        <h5 className="h5 text-primary font-monospace">{ link.title} factures </h5>
                                                        <p class="text-secondary"><span class="ms-1 small">Trouver les factures des frais scolaires</span></p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card shadow mb-2  shadow border border-1 mb-2">
                                                <Link to={"/#disciplines"} className="text-decoration-none">
                                                    <img class="card-img-top" src="assets/img/marketing/marketing03.png" alt="" />
                                                    <div className="card-body text-center">
                                                        <h5 className="h5 text-primary font-monospace">{ link.title} discipline</h5>
                                                        <p class="text-secondary"><span class="ms-1 small">voir le rapport de discipline</span></p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </>)
                                })
                            }

                        </div>
                        
                        {
                            this.state.users.map(user => {
                                if(user.userId)
                                {
                                    return(
                                        <div className="card shadow mt-4 mb-4 border rounded rounded-1">
                                            <div className="card-body">
                                                <Reportcard userId={user.id} />
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                        
                        {
                            !this.state.loading && (<Loading />)
                        }
                        
                        


                    </div>
                </div>
            </section>
        )
    }

}
export default Profile