import axios from "axios";
import React, {Component} from "react";
import '../assets/css/chat.css'
import Loading from "../components/Loading";
import FeatherIcon from 'feather-icons-react';

class Notification extends Component{

    state = {
        loading : false,
        chats : [],
    }

    componentDidMount(){
        this.getNotifications()
    }
     

    async getNotifications()
    {
        var chats = await axios.get('notifications');
        if(chats.status == 200)
        {
            this.setState({
                chats : chats.data,
                loading : true
            })
        }
        else{
            window.location.replace="/";
        }
    }

    async readAt(userchat){
        await axios.put("notifications/" + userchat )
        this.getNotifications()
    }

    render(){

        return (
            <div className="container pt-7">
                    <div className="card">
                        <div className="row g-0">

                            <div className="col-12 border shadow ">
                                <div className={ "py-2 px-2 border  "}>
                                    <div class="d-flex bd-highlight">
                                        <div class="p-2 w-100 bd-highlight">
                                            <div className="h5 font-monospace my-1">Notifications</div></div>
                                        <div class="p-2 flex-shrink-1 bd-highlight">
                                            <button className="btn border-primary btn-sm mr-1 ">
                                                <FeatherIcon icon={"bell"} size={14}  className="mb-1 fw-bolder text-primary"></FeatherIcon>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="position-relative">
                                    <div className="chat-messages p-4">

                                        {
                                            this.state.chats.map((chat) => {
                                                
                                                    return (
                                                        <div className="chat-message-left pb-4" key={chat.id}>
                                                            <div className={ chat.userchats.readAt == null ? "flex-shrink-1 bg-light rounded py-2 px-3 mr-3   rounded rounded-3" : "rounded py-1 px-3 mr-3 border border-2 border-light  rounded rounded-3"} onClick={() => chat.userchats.readAt == null && ( this.readAt( chat.userchats.id))}>
                                                                {chat.chat}
                                                            </div>
                                                            <div>
                                                                {/* <img src={ chat.avatar} className="rounded-circle mr-1" alt="." width="40" height="40" /> */}
                                                                <div className="text-muted small text-nowrap mt-2">{chat.time}</div>
                                                            </div>
                                                        </div>
                                                    )
                                            })
                                        }

                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
export default Notification