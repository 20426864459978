import React, {Component} from "react";

class Intervenant extends Component{

    render(){
        return(
          <section className="pt-5" id="intervenant">

          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h5 className="text-secondary">Processus</h5>
                <h2 className="mb-2 fs-7 fw-bold">Collaboration</h2>
                <p className="mb-4 fw-medium text-secondary">
                Collaboration directe et non physique entre
                l'enseignant et le parent dans le candre de renforcer
                <br />le niveau et l'éducation de l'élève .
                </p>
                <h4 className="fs-1 fw-bold">Accessibilité</h4>
                <p className="mb-4 fw-medium text-secondary">Par l'enseignant, parent ou élève ayant l'autorisation et l'accessibilité d'y acceder ,<br />authentification et information aprouvée</p>
                <h4 className="fs-1 fw-bold">Collaboration</h4>
                <p className="mb-4 fw-medium text-secondary">Le système confirme le parent par sms le paiement de l'élève <br /> et transmet le parcours des évalutions de l'élève ,</p>
                <h4 className="fs-1 fw-bold">Transmettre</h4>
                <p className="mb-4 fw-medium text-secondary">Partager les informations entre enseignant et entre parent ou élève <br /> communication interne et externe integrée</p>
              </div>
              <div className="col-lg-6"><img className="img-fluid" src="assets/img/validation/validation.png" alt="" /></div>
            </div>
          </div>
  
        </section>
        )
    }
}
export default Intervenant;