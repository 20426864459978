import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import { Link } from "react-router-dom";
import Loading from "../components/Loading";

class Schoolfee extends Component{

    state = {
        loading : false,
        student : {
            user : {
                responsible : {}
            }
        },
        schoolfees : [],
    }

    componentDidMount(){
        this.getUser()
    }

    async getUser(){
        var student = await axios.get("students/"+this.props.match.params.student);
        if(student.status === 200)
        {
            this.setState({
                student : student.data.data,
                loading : true
            })
            this.getStudentschoolfees()
        }
    }
    
    async getStudentschoolfees(){
        var schoolfees =  await axios.get('schoolfees/user/'+this.state.student.user.id)
        if(schoolfees.status === 200)
        {
            this.setState({
                schoolfees : schoolfees.data.data
            })
        }
    }

    render(){
        var  amount = 0, currency="", schoolfees = "";
        
        schoolfees = 
        this.state.schoolfees.map((invoice) => {
            amount += invoice.pay_mount
            currency =  invoice.schoolfeecategory.currency
            var first_name = invoice.student.first_name ? invoice.student.first_name : "";
                return (
                    <div className="col-md-6"  key={invoice.id}>
                        <div className="card shadow mb-2 border border-1 rounded-3">
                            <div className="card-body">
                                <div className="overflow-hidden">
                                    <div className=" my-1">
                                        <span className="h6 font-monospace">{invoice.classe.short_name}</span> ,  
                                        <span className="text-dark small font-monospace ms-2">{invoice.classe.tel}</span>
                                    </div>
                                    
                                    
                                    <div className="mt-1">
                                        <span className="h5 font-monospace me-2">Reçu N°{invoice.invoice}</span>
                                           de <span className="text-dark small font-monospace">{ invoice.student.last_name + " " + invoice.student.family_name + " " + first_name}</span>, paie une 
                                         somme de <span className="text-dark small font-monospace">{ invoice.pay_mount + "" + invoice.schoolfeecategory.currency}</span>  
                                         <span className="text-dark small font-monospace"> {invoice.schoolfeecategory.category } </span> mois de <span className="text-dark small font-monospace">{ invoice.month.month}</span> <br />
                                        <div className="text-end pe-5 me-5">
                                            Uvira, le { invoice.created_at}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        })
        
        return(
            <section className="pt-7">
                <div className="container">
                    <div className="row align-items-center">
                
                        <div className="card border border-1 border-1 shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="fw-bolder text-center font-monospace">Factures de l'élève</div>
                                    <div className="col-md-4">
                                        <div className="d-flex">
                                            <div className="image mr-3">
                                                <img src={this.state.student && (this.state.student.user.avatar)} className="rounded-circle img-fluid" width={this.state.student && (this.state.student.user.avatar && ("100"))} /> 
                                            </div>
                                            <div className="mt-4">
                                                <div><b>{this.state.student && (this.state.student.user.matricule + " # " + this.state.student.user.identity_number)}</b> 
                                                </div>
                                                
                                                <div className=" m-1">

                                                    {
                                                        this.state.student && (
                                                            this.state.student.user.profiles &&(
                                                                this.state.student.user.profiles.map((profile) =>{
                                                                    if(profile.status == 1)
                                                                    {
                                                                        return(
                                                                            <span key={profile.id} className="badge bg-success small rounded-3 mx-1" type="button" onClick={() => this.showModalProfile(profile, this.state.student)}>{profile.profile}</span>
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>
                                            <FeatherIcon icon="user" size="16" className={"text-primary "}></FeatherIcon>{this.state.student && (this.state.student.user.first_name + " "+ this.state.student.user.last_name + " "+ this.state.student.user.family_name + " " + this.state.student.user.first_name)} 
                                        </div>
                                        <div>
                                            <a href={"tel:"+this.state.student && (this.state.student.user.phone_number)} rel="noopener noreferrer" target="_blank" className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16" className={"text-primary "}></FeatherIcon>{this.state.student && (this.state.student.user.phone_number)}</a> </div>
                                        <div>
                                            <a href={"mailto:"+this.state.student && (this.state.student.user.email)} rel="noopener noreferrer" target="_blank"  className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.email)}</a> </div>
                                    
                                        <div>
                                            <FeatherIcon icon="map-pin" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.adresse)}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>
                                            <FeatherIcon icon="box" size="16" className={"text-primary "}></FeatherIcon>
                                            {this.state.student && (this.state.student.establishmentsectionclasse && (this.state.student.establishmentsectionclasse.short_entitled_class + " " + this.state.student.establishmentsectionclasse.title))} { this.state.student.scolary_year}
                                        </div>
                                        <div>
                                            <FeatherIcon icon="home" size="16" className={"text-primary "}></FeatherIcon>
                                            {this.state.student && (this.state.student.establishmentsectionclasse && (this.state.student.establishmentsectionclasse.entitled_section))}
                                        </div>
                                        <div>
                                            <FeatherIcon icon="dollar-sign" size="16" className={"text-primary "}></FeatherIcon>
                                            Net <b>{ amount + "" + currency}</b>
                                        </div>
                                        <div>
                                            <FeatherIcon icon="divide" size="16" className={"text-primary "}></FeatherIcon>
                                            Points { this.state.student.studentmax}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                        {
                            !this.state.loading && ( <Loading />)
                        }
                        <div className="col-12 row">
                        {
                            schoolfees
                        }
                        </div>
                        <div className="text-end"><Link to={"/reportcards/"+this.props.match.params.student} className="btn btn-primary btn-sm  me-3 btn-lg" type="button">Voir aussi son parcours</Link></div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Schoolfee;