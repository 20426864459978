import React, {Component} from "react";
import ReactLoading from "react-loading";
import FeatherIcon from 'feather-icons-react';
import axios from "axios";

class Student extends Component{

    state = {
        student : null,
        studentschoolfees : [],
        disciplines : [],
        discipline : false,
        isStudent : true,
        loading : true,
        message : "Recherche encours...",
        schoolfee : false
    }

    handleInputS = (e) => {
        this.setState({
            isStudent: !this.state.isStudent,
            discipline: false,
            schoolfee: false,
        }); 
    }

    handleInputD = (e) => {
        this.setState({
            discipline: !this.state.discipline,
            isStudent: false,
            schoolfee: false,
        }); 
    }
    handleInputF = (e) => {
        this.setState({
            schoolfee: !this.state.schoolfee,
            isStudent: false,
            discipline: false,
        }); 
    }

    componentDidMount(){
        this.getStudent()
    }

    async getStudent(){
        var student = await axios.get("userapi/" + encodeURIComponent(this.props.match.params.id));
        // var student = await axios.get("userapi/94ce1d73-d8ef-453d-b533-a45e29c7e8ba");
        
        if(student.status === 200)
        {
            this.setState({
                message : "",
                loading : false,
                student : student.data
            })
            
            this.getStudentschoolfees(student.data.id);
            this.getDisciplines(student.data.id);
        }else{
            this.setState({
                loading : false,
                message : "Eleve non trouvé"
            })
        }
    }

    async getStudentschoolfees(studentId){
        var studentschoolfees = await axios.get('/schoolfees/'+ studentId)
        if(studentschoolfees.status === 200)
        {
            this.setState({
                studentschoolfees : studentschoolfees.data
            })
        }
    }


    async getDisciplines(student){
        var disciplines = await axios.get("disciplines/" + student);
        
        if(disciplines.status === 200)
        {
            this.setState({
                disciplines : disciplines.data
            })
        }
    }

    render(){
        var last_name = this.state.student?.user ? this.state.student?.user.last_name : "";
        var first_name = this.state.student?.user ? this.state.student?.user.first_name : "";
        var family_name = this.state.student?.user ? this.state.student?.user.family_name : "";
        var classe= this.state.student && (this.state.student.sectionclass && (this.state.student.sectionclass.class.short_entitled_class + " " + this.state.student.sectionclass.title )); 
        var section= this.state.student && (this.state.student.sectionclass && ( this.state.student.sectionclass.section.entitled_section )); 
        
        return(
            <main className="main" id="top">
                <nav className="navbar opacity-100 navbar-expand-lg sticky-top  p-0 m-0" data-navbar-on-scroll="data-navbar-on-scroll" style={{ background : "rgb(7, 32, 250)"}}>
                    <div className="container  p-0 m-0" style={{ background : "rgb(7, 32, 250)"}}>
                        <div>
                            <br />
                            <br />
                            <br />
                                <a className="text-decoration-none" href={"/qrcode/" + encodeURIComponent(this.props.match.params.id)}><span className=""><img src={"/logo51.png" } alt="logo evive" className="bg-white p-0 m-0" height={120+"px"}  /></span></a>
                            <br />
                            <br />
                            <br />
                        </div>
                        <div className="border-bottom border-lg-0 mt-lg-0 flex-row-reverse" id="navbarSupportedContent" style={{ background : "rgb(7, 32, 250)"}}>
                            <ul className="navbar-nav ms-auto">
                            
                                <li className="nav-item ms-1 border border-0 ps-4 pe-1" style={{ background : "white" }}> <a className="nav-link btn" aria-current="page" href={ "#student" } onClick={ () => this.handleInputS()}> <FeatherIcon icon={"user"} size={14}  className="mb-1 fw-bolder"></FeatherIcon> Identité </a> </li>
                                <li className="nav-item ms-1 border border-0 ps-4 pe-1" style={{ background : "white" }}> <a className="nav-link btn" aria-current="page" href={ "#schoolfree" } onClick={ () => this.handleInputF()}> <FeatherIcon icon={"command"} size={14}  className="mb-1 fw-bolder"></FeatherIcon> Paiement </a> </li>
                                <li className="nav-item ms-1 border border-0 ps-4 pe-1" style={{ background : "white" }}> <a className="nav-link btn" aria-current="page" href={ "#score" } onClick={ () => this.handleInputD()}> <FeatherIcon icon={"activity"} size={14}  className="mb-1 fw-bolder"></FeatherIcon> Parcours </a> </li>
                                
                            </ul>
                        </div>
                    </div>
                </nav>

                <section className={ this.state.isStudent ? "pt-7" : "d-none"}>
                    <div className="container">
                        <div className={ this.state.loading ? "text-danger text-red text-center" : "d-none"}> <ReactLoading type="spokes" color="red" height={40} width={30} />{ this.state.message }</div>
                        <div className="mt-2">
                            <FeatherIcon icon={"slack"} size={14}  className="fw-bold"></FeatherIcon> Matricule élève <br /> <span className="ms-3 fw-bold font-cursive" style={{ color : "rgb(7, 32, 250)"}}>{this.state.student && (this.state.student.matricule)}</span>
                        </div>
                        <div className="mt-2">
                            <FeatherIcon icon={"user"} size={14}  className="fw-bold"></FeatherIcon> Nom élève <br /> <span className="ms-3 fw-bold" style={{ color : "rgb(7, 32, 250)"}}>{ last_name } { first_name } { family_name }</span>
                        </div>
                        <div className="mt-2">
                            <FeatherIcon icon={"home"} size={14}  className="fw-bold"></FeatherIcon> Adresse <br /> <span className="ms-3 fw-semi-bold" style={{ color : "rgb(7, 32, 250)"}}>{this.state.student && (this.state.student.user.adress)}</span>
                        </div>
                        <div className="mt-2">
                            <FeatherIcon icon={"bookmark"} size={14}  className="fw-bold"></FeatherIcon> Classe <br /> <span className="ms-3 fw-semi-bold" style={{ color : "rgb(7, 32, 250)"}}>{ classe } <i className="">{ section } </i> {this.state.student && (this.state.student.year ? this.state.student.year.year : "")}</span>
                        </div>

                        <br />
                        <br />
                        <div className="mt-2">
                            <FeatherIcon icon={"grid"} size={14}  className="fw-bold"></FeatherIcon> Etablissement <br /> <span className="ms-3 fw-semi-bold" style={{ color : "rgb(7, 32, 250)"}}>{ this.state.student && ( this.state.student.establishment.name )}</span>
                        </div>
                        <div className="mt-2">
                            <FeatherIcon icon={"phone"} size={14}  className="fw-bold"></FeatherIcon> Contact etablissement <br /> <span className="text-primary ms-3 fw-600"> { this.state.student && ( this.state.student.establishment.email)} , { this.state.student && ( this.state.student.establishment.tel)} </span>
                        </div>
                        
                        <br />
                        <br />
                        <div className="mt-2">
                            <FeatherIcon icon={"users"} size={14}  className="fw-bold"></FeatherIcon> Parent <br /> <span className="ms-3 fw-semi-bold" style={{ color : "rgb(7, 32, 250)"}}>{this.state.student && ( this.state.student.user.user.last_name)} {this.state.student && ( this.state.student.user.user.first_name)}</span>
                        </div>
                        <div className="mt-2">
                            <FeatherIcon icon={"phone-call"} size={14}  className="fw-bold"></FeatherIcon> Contact parent <br /> <span className="text-primary ms-3 fw-600">{this.state.student && (this.state.student.user.user.tel)},  {this.state.student && (this.state.student.user.user.email)}</span>
                        </div>
                    </div>
                </section>

                <section className={this.state.schoolfee ? "pt-7 container" : "d-none"}>
                    <div className=" col-md-12 font-monospace mt-4">

                        <div className={this.state.schoolfee ? "bg-primary text-center text-white font-monospace fw-bolder my-2" : "bg-primary text-center text-white font-monospace fw-bolder my-2 d-none"}>Paiements scolaire</div>
                        <div className={this.state.schoolfee ? "row" : "row d-none" }>
                            {
                                this.state.studentschoolfees != null &&(
                                    this.state.studentschoolfees?.schoolfees?.filter(sh => this.state.studentschoolfees.status == 1).map((schoolfee, index) =>{
                                        return(
                                            <div className="col-md-4">
                                                <div className="card shadow mb-2 border rounded rounded-1">
                                                    <div className="card-body my-2 text-center">
                                                        <h6 className="h6 font-cursive">{ schoolfee.month.month }</h6>
                                                        <div className="font-monospace">{ schoolfee.invoice }. { schoolfee.sectionschoolfeecategory.schoolfeecategory.category }</div>
                                                        <div className="text-center m-2"><span className="border rounded rounded-2 text-primary p-2">{ schoolfee.pay_mount + ""+ schoolfee.sectionschoolfeecategory.currency }</span></div>
                                                        <div className="font-cursive small">{ schoolfee.user.last_name +  " " + schoolfee.user.first_name }, <small>{ new Date(schoolfee.createdAt).toLocaleDateString("en-GB") }</small></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>

                    </div>
                </section>
                
                <section className={ this.state.discipline ? "pt-7 container" : "d-none"}>
                    <div className="col-md-12 font-monospace mt-4">
                        <div className={this.state.discipline ? "bg-primary text-center text-white font-monospace fw-bolder my-2" : "bg-primary text-center text-white font-monospace fw-bolder my-2 d-none"}>Discipline</div>
                        <div className={this.state.discipline ? "row" : "row d-none" }>
                            {
                                this.state.disciplines.map((discipline, index) =>{
                                    return(
                                        <div className="col-md-4">
                                            <div className="card shadow mb-2 border rounded rounded-1">
                                                <div className="card-body mb-0 text-center">
                                                    <h6 className="h4 font-cursive">{ discipline.motif }</h6>
                                                    <div className="font-monospace small">{ discipline.description }.</div>
                                                </div>
                                                    <div className="text-end m-2 mt-0 small"><small>{ discipline.createdAt.slice(0, 10) }</small></div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    
                    </div>
                </section>

            </main>
        )
    }
}
export default Student;