import React, {Component} from "react";

class Design extends Component{

    render(){
      var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : false;
        return(
        <section className="pt-7">
            <div className="container">
              <div className="row align-items-center">
                <div className="">
                  {/* <div className="form-group d-flex">
                    <input className="form-control form-control-sm me-1" name="establishment" placeholder="Etablissement" />
                    <input className="form-control from-control-sm me-1" name="student" placeholder="élève" aria-current="page" href="#profile" />
                  </div> */}
                </div>
                <div className="col-md-6 text-md-start text-center py-6">
                  <h1 className="mb-4 fs-9 fw-bold">Solution scolaire</h1>
                  <p className="mb-6 lead text-secondary">Suivi pédagogique, faciliter les processus journalier,<br className="d-none d-xl-block" /> fournir votre ambiance du surchage,<br className="d-none d-xl-block" /> votre gain du temps est gaté grâce à cette solution .</p>
                    <div className="text-center text-md-start">
                      { 
                        user != false && (
                          <a className="btn btn-primary btn-sm  me-3 btn-lg" type="button" aria-current="page" href="#profile" >Paiement et parcours</a>
                        )
                      } 
                      { 
                        user == false && (
                          <button className="btn btn-primary btn-sm  me-3 btn-lg" type="button"  data-bs-toggle="modal" data-bs-target="#popupLogin" >Se lancer</button>
                        )
                      } 
                      {/* <a className="btn btn-link text-primary fw-medium text-decoration-none" href="#!" role="button" data-bs-toggle="modal" data-bs-target="#popupVideo"><span className="fas fa-play me-2"></span>Voir la video </a> */}
                    
                  </div>
                </div>
                <div className="col-md-6 text-end"><img className="pt-7 pt-md-0 img-fluid" src="assets/img/hero/hero-img.png" alt="" /></div>
              </div>
            </div>

            {/* <div className="modal fade" id="popupVideo" tabIndex="-1" aria-labelledby="popupVideo" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <iframe className="rounded" style={{width:100+"%", height:500+"px"}} src="https://www.youtube.com/embed/_lhdhL4UDIo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            </div> */}
        </section>
        )
    }
}
export default Design;