import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RouterPage from "./RouterPage";

function App() {
  return (
    <Router>
          <RouterPage />
    </Router>
  );
}

export default App;
