import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import Schoolfee from "./pages/Schoolfee";
import Reportcard from "./pages/Reportcard";
import Notification from "./pages/Notification";
import Student from "./pages/Student";

 
function RouterPage(){

    return(
        <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/qrcode/:id" component={Student} />
            <Route exact path="/reportcards" component={Reportcard} />
            <Route exact path="/schoolfees" component={Schoolfee} />
            <Route exact path="/notifications" component={Notification} />
            <Route component={NotFound} />
        </Switch>
    )
}

export default RouterPage;